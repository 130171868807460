import { formatScriptContent, positionToLocation, } from "./vendor/models/formatter/ScriptFormatter.js";
export async function formatWithMap(source, indent) {
    const formatted = await formatScriptContent("text/javascript", source, indent);
    const fsm = formatted.formattedMapping;
    const mapping = new Mapper(fsm.originalLineEndings, fsm.formattedLineEndings, fsm.mapping);
    return { mapping, content: formatted.formattedContent };
}
export class Mapper {
    constructor(originalLineEndings, formattedLineEndings, mapping) {
        this.originalLineEndings = originalLineEndings;
        this.formattedLineEndings = formattedLineEndings;
        this.mapping = mapping;
    }
    positionToLocationOriginal(originalPosition) {
        return positionToLocation(this.originalLineEndings, originalPosition);
    }
    positionToLocationFormatted(formattedPosition) {
        return positionToLocation(this.formattedLineEndings, formattedPosition);
    }
    *iterateMappings() {
        for (let i = 0; i < this.mapping.formatted.length; i++) {
            const generated = this.positionToLocationFormatted(this.mapping.formatted[i]);
            const original = this.positionToLocationOriginal(this.mapping.original[i]);
            yield {
                generated: { line: generated[0] + 1, column: generated[1] },
                original: { line: original[0] + 1, column: original[1] },
            };
        }
    }
}
