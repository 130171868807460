/*
 * Copyright (C) 2011 Google Inc. All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 *     * Redistributions of source code must retain the above copyright
 * notice, this list of conditions and the following disclaimer.
 *     * Redistributions in binary form must reproduce the above
 * copyright notice, this list of conditions and the following disclaimer
 * in the documentation and/or other materials provided with the
 * distribution.
 *     * Neither the name of Google Inc. nor the names of its
 * contributors may be used to endorse or promote products derived from
 * this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _JavaScriptFormatter_instances, _JavaScriptFormatter_builder, _JavaScriptFormatter_tokenizer, _JavaScriptFormatter_content, _JavaScriptFormatter_fromOffset, _JavaScriptFormatter_lastLineNumber, _JavaScriptFormatter_toOffset, _JavaScriptFormatter_push, _JavaScriptFormatter_beforeVisit, _JavaScriptFormatter_afterVisit, _JavaScriptFormatter_inForLoopHeader, _JavaScriptFormatter_formatToken, _JavaScriptFormatter_finishNode;
import * as Acorn from '../../third_party/acorn/acorn.js';
import { AcornTokenizer, ECMA_VERSION } from './AcornTokenizer.js';
import { ESTreeWalker } from './ESTreeWalker.js';
export class JavaScriptFormatter {
    constructor(builder) {
        _JavaScriptFormatter_instances.add(this);
        _JavaScriptFormatter_builder.set(this, void 0);
        _JavaScriptFormatter_tokenizer.set(this, void 0);
        _JavaScriptFormatter_content.set(this, void 0);
        _JavaScriptFormatter_fromOffset.set(this, void 0);
        _JavaScriptFormatter_lastLineNumber.set(this, void 0);
        _JavaScriptFormatter_toOffset.set(this, void 0);
        __classPrivateFieldSet(this, _JavaScriptFormatter_builder, builder, "f");
    }
    format(text, lineEndings, fromOffset, toOffset) {
        __classPrivateFieldSet(this, _JavaScriptFormatter_fromOffset, fromOffset, "f");
        __classPrivateFieldSet(this, _JavaScriptFormatter_toOffset, toOffset, "f");
        __classPrivateFieldSet(this, _JavaScriptFormatter_content, text.substring(__classPrivateFieldGet(this, _JavaScriptFormatter_fromOffset, "f"), __classPrivateFieldGet(this, _JavaScriptFormatter_toOffset, "f")), "f");
        __classPrivateFieldSet(this, _JavaScriptFormatter_lastLineNumber, 0, "f");
        __classPrivateFieldSet(this, _JavaScriptFormatter_tokenizer, new AcornTokenizer(__classPrivateFieldGet(this, _JavaScriptFormatter_content, "f")), "f");
        const ast = Acorn.parse(__classPrivateFieldGet(this, _JavaScriptFormatter_content, "f"), {
            ranges: false,
            preserveParens: true,
            allowImportExportEverywhere: true,
            ecmaVersion: ECMA_VERSION,
            allowHashBang: true,
        });
        const walker = new ESTreeWalker(__classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_beforeVisit).bind(this), __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_afterVisit).bind(this));
        // @ts-ignore Technically, the acorn Node type is a subclass of Acorn.ESTree.Node.
        // However, the acorn package currently exports its type without specifying
        // this relationship. So while this is allowed on runtime, we can't properly
        // typecheck it.
        walker.walk(ast);
    }
}
_JavaScriptFormatter_builder = new WeakMap(), _JavaScriptFormatter_tokenizer = new WeakMap(), _JavaScriptFormatter_content = new WeakMap(), _JavaScriptFormatter_fromOffset = new WeakMap(), _JavaScriptFormatter_lastLineNumber = new WeakMap(), _JavaScriptFormatter_toOffset = new WeakMap(), _JavaScriptFormatter_instances = new WeakSet(), _JavaScriptFormatter_push = function _JavaScriptFormatter_push(token, format) {
    for (let i = 0; i < format.length; ++i) {
        if (format[i] === 's') {
            __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").addSoftSpace();
        }
        else if (format[i] === 'S') {
            __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").addHardSpace();
        }
        else if (format[i] === 'n') {
            __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").addNewLine();
        }
        else if (format[i] === '>') {
            __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").increaseNestingLevel();
        }
        else if (format[i] === '<') {
            __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").decreaseNestingLevel();
        }
        else if (format[i] === 't') {
            if (__classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").tokenLineStart() - __classPrivateFieldGet(this, _JavaScriptFormatter_lastLineNumber, "f") > 1) {
                __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").addNewLine(true);
            }
            __classPrivateFieldSet(this, _JavaScriptFormatter_lastLineNumber, __classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").tokenLineEnd(), "f");
            if (token) {
                __classPrivateFieldGet(this, _JavaScriptFormatter_builder, "f").addToken(__classPrivateFieldGet(this, _JavaScriptFormatter_content, "f").substring(token.start, token.end), __classPrivateFieldGet(this, _JavaScriptFormatter_fromOffset, "f") + token.start);
            }
        }
    }
}, _JavaScriptFormatter_beforeVisit = function _JavaScriptFormatter_beforeVisit(node) {
    if (!node.parent) {
        return;
    }
    let token;
    while ((token = __classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").peekToken()) && token.start < node.start) {
        const token = __classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").nextToken();
        // @ts-ignore Same reason as above about Acorn types and ESTree types
        const format = __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_formatToken).call(this, node.parent, token);
        __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_push).call(this, token, format);
    }
    return;
}, _JavaScriptFormatter_afterVisit = function _JavaScriptFormatter_afterVisit(node) {
    let token;
    while ((token = __classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").peekToken()) && token.start < node.end) {
        const token = __classPrivateFieldGet(this, _JavaScriptFormatter_tokenizer, "f").nextToken();
        const format = __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_formatToken).call(this, node, token);
        __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_push).call(this, token, format);
    }
    __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_push).call(this, null, __classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_finishNode).call(this, node));
}, _JavaScriptFormatter_inForLoopHeader = function _JavaScriptFormatter_inForLoopHeader(node) {
    const parent = node.parent;
    if (!parent) {
        return false;
    }
    if (parent.type === 'ForStatement') {
        const parentNode = parent;
        return node === parentNode.init || node === parentNode.test || node === parentNode.update;
    }
    if (parent.type === 'ForInStatement' || parent.type === 'ForOfStatement') {
        const parentNode = parent;
        return node === parentNode.left || node === parentNode.right;
    }
    return false;
}, _JavaScriptFormatter_formatToken = function _JavaScriptFormatter_formatToken(node, tokenOrComment) {
    const AT = AcornTokenizer;
    if (AT.lineComment(tokenOrComment)) {
        return 'tn';
    }
    if (AT.blockComment(tokenOrComment)) {
        return 'tn';
    }
    const token = tokenOrComment;
    if (node.type === 'ContinueStatement' || node.type === 'BreakStatement') {
        return node.label && AT.keyword(token) ? 'ts' : 't';
    }
    if (node.type === 'Identifier') {
        return 't';
    }
    if (node.type === 'PrivateIdentifier') {
        return 't';
    }
    if (node.type === 'ReturnStatement') {
        if (AT.punctuator(token, ';')) {
            return 't';
        }
        return node.argument ? 'ts' : 't';
    }
    if (node.type === 'AwaitExpression') {
        if (AT.punctuator(token, ';')) {
            return 't';
        }
        return node.argument ? 'ts' : 't';
    }
    if (node.type === 'Property') {
        if (AT.punctuator(token, ':')) {
            return 'ts';
        }
        return 't';
    }
    if (node.type === 'ArrayExpression') {
        if (AT.punctuator(token, ',')) {
            return 'ts';
        }
        return 't';
    }
    if (node.type === 'LabeledStatement') {
        if (AT.punctuator(token, ':')) {
            return 'ts';
        }
    }
    else if (node.type === 'LogicalExpression' || node.type === 'AssignmentExpression' || node.type === 'BinaryExpression') {
        if (AT.punctuator(token) && !AT.punctuator(token, '()')) {
            return 'sts';
        }
    }
    else if (node.type === 'ConditionalExpression') {
        if (AT.punctuator(token, '?:')) {
            return 'sts';
        }
    }
    else if (node.type === 'VariableDeclarator') {
        if (AT.punctuator(token, '=')) {
            return 'sts';
        }
    }
    else if (node.type === 'ObjectPattern') {
        if (node.parent && node.parent.type === 'VariableDeclarator' && AT.punctuator(token, '{')) {
            return 'st';
        }
        if (AT.punctuator(token, ',')) {
            return 'ts';
        }
    }
    else if (node.type === 'FunctionDeclaration') {
        if (AT.punctuator(token, ',)')) {
            return 'ts';
        }
    }
    else if (node.type === 'FunctionExpression') {
        if (AT.punctuator(token, ',)')) {
            return 'ts';
        }
        if (AT.keyword(token, 'function')) {
            return node.id ? 'ts' : 't';
        }
    }
    else if (node.type === 'WithStatement') {
        if (AT.punctuator(token, ')')) {
            return node.body && node.body.type === 'BlockStatement' ? 'ts' : 'tn>';
        }
    }
    else if (node.type === 'SwitchStatement') {
        if (AT.punctuator(token, '{')) {
            return 'tn>';
        }
        if (AT.punctuator(token, '}')) {
            return 'n<tn';
        }
        if (AT.punctuator(token, ')')) {
            return 'ts';
        }
    }
    else if (node.type === 'SwitchCase') {
        if (AT.keyword(token, 'case')) {
            return 'n<ts';
        }
        if (AT.keyword(token, 'default')) {
            return 'n<t';
        }
        if (AT.punctuator(token, ':')) {
            return 'tn>';
        }
    }
    else if (node.type === 'VariableDeclaration') {
        if (AT.punctuator(token, ',')) {
            let allVariablesInitialized = true;
            const declarations = node.declarations;
            for (let i = 0; i < declarations.length; ++i) {
                // @ts-ignore We are doing a subtype check, without properly checking whether
                // it exists. We can't fix that, unless we use proper typechecking
                allVariablesInitialized = allVariablesInitialized && Boolean(declarations[i].init);
            }
            return !__classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_inForLoopHeader).call(this, node) && allVariablesInitialized ? 'nSSts' : 'ts';
        }
    }
    else if (node.type === 'PropertyDefinition') {
        if (AT.punctuator(token, '=')) {
            return 'sts';
        }
        if (AT.punctuator(token, ';')) {
            return 'tn';
        }
    }
    else if (node.type === 'BlockStatement') {
        if (AT.punctuator(token, '{')) {
            return node.body.length ? 'tn>' : 't';
        }
        if (AT.punctuator(token, '}')) {
            return node.body.length ? 'n<t' : 't';
        }
    }
    else if (node.type === 'CatchClause') {
        if (AT.punctuator(token, ')')) {
            return 'ts';
        }
    }
    else if (node.type === 'ObjectExpression') {
        if (!node.properties.length) {
            return 't';
        }
        if (AT.punctuator(token, '{')) {
            return 'tn>';
        }
        if (AT.punctuator(token, '}')) {
            return 'n<t';
        }
        if (AT.punctuator(token, ',')) {
            return 'tn';
        }
    }
    else if (node.type === 'IfStatement') {
        if (AT.punctuator(token, ')')) {
            return node.consequent && node.consequent.type === 'BlockStatement' ? 'ts' : 'tn>';
        }
        if (AT.keyword(token, 'else')) {
            const preFormat = node.consequent && node.consequent.type === 'BlockStatement' ? 'st' : 'n<t';
            let postFormat = 'n>';
            if (node.alternate && (node.alternate.type === 'BlockStatement' || node.alternate.type === 'IfStatement')) {
                postFormat = 's';
            }
            return preFormat + postFormat;
        }
    }
    else if (node.type === 'CallExpression') {
        if (AT.punctuator(token, ',')) {
            return 'ts';
        }
    }
    else if (node.type === 'SequenceExpression' && AT.punctuator(token, ',')) {
        return node.parent && node.parent.type === 'SwitchCase' ? 'ts' : 'tn';
    }
    else if (node.type === 'ForStatement' || node.type === 'ForOfStatement' || node.type === 'ForInStatement') {
        if (AT.punctuator(token, ';')) {
            return 'ts';
        }
        if (AT.keyword(token, 'in') || AT.identifier(token, 'of')) {
            return 'sts';
        }
        if (AT.punctuator(token, ')')) {
            return node.body && node.body.type === 'BlockStatement' ? 'ts' : 'tn>';
        }
    }
    else if (node.type === 'WhileStatement') {
        if (AT.punctuator(token, ')')) {
            return node.body && node.body.type === 'BlockStatement' ? 'ts' : 'tn>';
        }
    }
    else if (node.type === 'DoWhileStatement') {
        const blockBody = node.body && node.body.type === 'BlockStatement';
        if (AT.keyword(token, 'do')) {
            return blockBody ? 'ts' : 'tn>';
        }
        if (AT.keyword(token, 'while')) {
            return blockBody ? 'sts' : 'n<ts';
        }
        if (AT.punctuator(token, ';')) {
            return 'tn';
        }
    }
    else if (node.type === 'ClassBody') {
        if (AT.punctuator(token, '{')) {
            return 'stn>';
        }
        if (AT.punctuator(token, '}')) {
            return '<ntn';
        }
        return 't';
    }
    else if (node.type === 'YieldExpression') {
        return 't';
    }
    else if (node.type === 'Super') {
        return 't';
    }
    else if (node.type === 'ImportExpression') {
        return 't';
    }
    else if (node.type === 'ExportAllDeclaration') {
        if (AT.punctuator(token, '*')) {
            return 'sts';
        }
        return 't';
    }
    else if (node.type === 'ExportNamedDeclaration' || node.type === 'ImportDeclaration') {
        if (AT.punctuator(token, '{')) {
            return 'st';
        }
        if (AT.punctuator(token, ',')) {
            return 'ts';
        }
        if (AT.punctuator(token, '}')) {
            return node.source ? 'ts' : 't';
        }
        if (AT.punctuator(token, '*')) {
            return 'sts';
        }
        return 't';
    }
    return AT.keyword(token) && !AT.keyword(token, 'this') ? 'ts' : 't';
}, _JavaScriptFormatter_finishNode = function _JavaScriptFormatter_finishNode(node) {
    if (node.type === 'WithStatement') {
        if (node.body && node.body.type !== 'BlockStatement') {
            return 'n<';
        }
    }
    else if (node.type === 'VariableDeclaration') {
        if (!__classPrivateFieldGet(this, _JavaScriptFormatter_instances, "m", _JavaScriptFormatter_inForLoopHeader).call(this, node)) {
            return 'n';
        }
    }
    else if (node.type === 'ForStatement' || node.type === 'ForOfStatement' || node.type === 'ForInStatement') {
        if (node.body && node.body.type !== 'BlockStatement') {
            return 'n<';
        }
    }
    else if (node.type === 'BlockStatement') {
        if (node.parent && node.parent.type === 'IfStatement') {
            const parentNode = node.parent;
            if (parentNode.alternate && parentNode.consequent === node) {
                return '';
            }
        }
        if (node.parent && node.parent.type === 'FunctionExpression' && node.parent.parent &&
            node.parent.parent.type === 'Property') {
            return '';
        }
        if (node.parent && node.parent.type === 'FunctionExpression' && node.parent.parent &&
            node.parent.parent.type === 'VariableDeclarator') {
            return '';
        }
        if (node.parent && node.parent.type === 'FunctionExpression' && node.parent.parent &&
            node.parent.parent.type === 'CallExpression') {
            return '';
        }
        if (node.parent && node.parent.type === 'DoWhileStatement') {
            return '';
        }
        if (node.parent && node.parent.type === 'TryStatement') {
            const parentNode = node.parent;
            if (parentNode.block === node) {
                return 's';
            }
        }
        if (node.parent && node.parent.type === 'CatchClause') {
            const parentNode = node.parent;
            // @ts-ignore We are doing a subtype check, without properly checking whether
            // it exists. We can't fix that, unless we use proper typechecking
            if (parentNode.parent && parentNode.parent.finalizer) {
                return 's';
            }
        }
        return 'n';
    }
    else if (node.type === 'WhileStatement') {
        if (node.body && node.body.type !== 'BlockStatement') {
            return 'n<';
        }
    }
    else if (node.type === 'IfStatement') {
        if (node.alternate) {
            if (node.alternate.type !== 'BlockStatement' && node.alternate.type !== 'IfStatement') {
                return '<';
            }
        }
        else if (node.consequent) {
            if (node.consequent.type !== 'BlockStatement') {
                return '<';
            }
        }
    }
    else if (node.type === 'BreakStatement' || node.type === 'ContinueStatement' || node.type === 'ThrowStatement' ||
        node.type === 'ReturnStatement' || node.type === 'ExpressionStatement') {
        return 'n';
    }
    else if (node.type === 'ImportDeclaration' || node.type === 'ExportAllDeclaration' ||
        node.type === 'ExportDefaultDeclaration' || node.type === 'ExportNamedDeclaration') {
        return 'n';
    }
    return '';
};
